// - - - - - - - - - - - - - - - - - - SIDEBAR MENU - - - - - - - - - - - - - - - //
var wrapper = document.querySelector('.wrapper');
var hamburger = document.querySelector('.hamburger');
// On click
hamburger.addEventListener('click', function () {
	// Toggle class "is-active"
	hamburger.classList.toggle('is-active');
	wrapper.classList.toggle('menu-open');
})

// - - - - - - - - - - - - - - - - - - SUBMENU ITEMS - - - - - - - - - - - - - - - //
var submenuitems = document.querySelectorAll('.submenu-item');

submenuitems.forEach(function(submenuitem) {
	// On click
	submenuitem.addEventListener('click', function(event) {
		// Toggle class "is-active"
		hamburger.classList.toggle('is-active');
		wrapper.classList.toggle('menu-open');
	});
});

// - - - - - - - - - - - - - - - - - - RESPONSIVE MENU - - - - - - - - - - - - - - - //
// Define our viewportWidth variable
var viewportWidth;
var isDesktop = false;
var isMobile = false;

// Set/update the viewportWidth value
var setViewportWidth = function () {
	viewportWidth = window.innerWidth || document.documentElement.clientWidth;
}

// Log the viewport width into the console
var logWidth = function () {
	if (viewportWidth > 768) {
		if(isMobile) {
			// console.log('SWITCHED TO DESKTOP');

			hamburger.classList.remove('is-active');
			wrapper.classList.remove('menu-open');
		}

		isDesktop = true;
		isMobile = false;
	} else {
		if(isDesktop) {
			// console.log('SWITCHED TO MOBILE');
		}

		isDesktop = false;
		isMobile = true;
	}
}

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener('resize', function () {
	setViewportWidth();
	logWidth();
}, false);