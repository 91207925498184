var activeModal

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

document.querySelectorAll('[data-toggle=modal]').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    if (this.dataset.target) {
      event.preventDefault()
      toggleModal(this.dataset.target)
    }
  })
})

document.querySelectorAll('[data-dismiss=modal]').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    if (this.dataset.target) {
      event.preventDefault()
      dismissModal(this.dataset.target)
    }
  })
})

document.querySelectorAll('[data-link]').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    if (this.dataset.target) {
      event.preventDefault()
      const linkOut = trigger.getAttribute('data-link');
      updateLinkOut(this.dataset.target, linkOut)
    }
  })
})

document.querySelectorAll('.continue-button').forEach(function (trigger) {
  trigger.addEventListener('click', function (event) {
    event.preventDefault();
    dismissModal(this.dataset.target, this.href)
  })
})

document.onkeydown = function (evt) {
  evt = evt || window.event
  var isEscape = false
  if ('key' in evt) {
    isEscape = (evt.key === 'Escape' || evt.key === 'Esc')
  } else {
    isEscape = (evt.keyCode === 27)
  }
  if (isEscape && activeModal) {
    dismissModal(activeModal)
  }
}

function dismissModal (target, linkOut) {
  const modal = document.querySelector(target)
  modal.classList.add('opacity-0')
  modal.classList.add('pointer-events-none')
  document.body.classList.remove('modal-active')
  activeModal = null
  if(linkOut){
    window.open(linkOut, '_blank');
  }
}

function toggleModal (target) {
  const modal = document.querySelector(target)
  modal.classList.toggle('opacity-0')
  modal.classList.toggle('pointer-events-none')
  document.body.classList.toggle('modal-active')
  activeModal = activeModal ? null : target
}

function updateLinkOut (target, link) {
  const modal = document.querySelector(target)
  const continueButton = modal.querySelector('.continue-button');
  continueButton.setAttribute('href', link);
}

module.exports = {
  dismissModal,
  toggleModal,
}



// CONGRESS CONFIRMATION COOKIE & MODAL
var cookieName = 'hcmsummit'; // The cookie name
var cookieLifetime = 7; // Cookie expiry in days

/**
* Set a cookie
* @param cname - cookie name
* @param cvalue - cookie value
* @param exdays - expiry in days
*/
var _setCookie = function (cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

/**
* Get a cookie
* @param cname - cookie name
* @returns string
*/
var _getCookie = function (cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
* Should the cookie popup be shown?
*/
var _shouldShowPopup = function () {
  if (_getCookie(cookieName)) {
    return false;
  } else {
    return true;
  }
};

// Show the cookie popup on load if not previously accepted
if (_shouldShowPopup()) {
  var congressModalElem = document.getElementById("congressModal");

  congressModalElem.classList.toggle('opacity-0');
  congressModalElem.classList.toggle('pointer-events-none');
  document.body.classList.toggle('modal-active');
  activeModal = activeModal ? null : congressModalElem
  }

// Modal dismiss btn - consent
var congressModalConsentBtn = document.getElementById("congressModalConsent");
congressModalConsentBtn.addEventListener('click', function (event) {
  _setCookie(cookieName, 1, cookieLifetime);
})